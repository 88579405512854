@import '@/styles/dashboard';
@import '@/styles/roundButton';
@import '@/styles/badge';

.dashboard {
  @include dashboard;
}

.content {
  &__jobs-count {
    display: flex;
    gap: 5px;
    margin-left: 20px;
  }

  &__search {
    min-width: 350px;
  }
}

.job-item {
  &__content {
    display: grid;
    grid-template-columns: 1fr calc(50% - 340px) 300px 32px;
    gap: 40px;
    align-items: center;
  }

  &__group {
    display: grid;
    align-items: center;
    min-width: 0; // Grid items have an initial size of min-width: auto
    height: 100%;

    &:not(:last-child) {
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        right: -20px;
        bottom: 0;
        width: 1px;
        content: '';
        background-color: var(--light-color-gray-10);
      }
    }

    &_controls {
      gap: 6px;
    }
  }

  &__name {
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1.15;
    word-break: break-all;
  }

  &__id {
    margin-top: 4px;
    font-size: 0.75em;
    color: var(--light-gray);
  }

  &__cancel-button {
    width: fit-content;
  }

  &__group-data {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 10px;
  }

  &__group-data-label {
    color: var(--light-gray);
  }

  &__group-data-status {
    @include badge;
  }

  &__logs {
    padding: 0;
    margin: 0 20px 20px;
    overflow: hidden;
    border-radius: 8px;
  }

  &__button {
    @include round-button;
  }
}

.status-selector {
  &__menu-list {
    &#{&} {
      max-height: 350px;
    }
  }

  &__menu {
    &#{&} {
      width: 171px;
      min-width: 171px;
    }
  }

  &__control {
    &#{&} {
      color: var(--light-color-gray-15);
    }
  }
}

.job-placeholder {
  height: 100px;
}
