.zoom {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__title {
    font-size: 17px;
  }

  &__range {
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
  }

  &__slider-value {
    display: none;
    margin: 0;
  }

  &__slider-label {
    margin: 0;
  }

  &__slider-input {
    max-width: 90px;

    input {
      font-family: SFPro, Arial, sans-serif;
      font-size: 17px;
      color: var(--color-black);
      border-color: var(--icons-border-color);
    }
  }

  &__range-slider {
    --slider-gap: 120px;

    width: calc(100% - var(--slider-gap));
    max-width: calc(100% - var(--slider-gap));
  }
}
