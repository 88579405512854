.download-icon {
  &_downloading {
    animation: downloading 1s infinite ease-out;

    @keyframes downloading {
      from {
        color: var(--color-black);
      }

      50% {
        color: var(--color-yellow-20);
      }

      to {
        color: var(--color-black);
      }
    }
  }
}

.label {
  position: absolute;
  font-size: 17px;
  font-weight: normal;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
}

.download-btn {
  display: grid;
  place-content: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
  background-color: var(--color-white-20);
  border-radius: 10px;

  &_active,
  &:hover:enabled {
    background-color: var(--color-yellow-10);
    border-color: var(--color-yellow-10);
  }
}
