@use 'sass:math';

$breakpoint-sm: 375px;
$breakpoint-md: 768px;
$breakpoint-mdx: 1024px;
$breakpoint-lg: 1440px;
$breakpoint-xl: 1920px;

@mixin xs {
  @media screen and (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{$breakpoint-sm}) and (max-width: #{$breakpoint-md - 1}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$breakpoint-md}) and (max-width: #{$breakpoint-lg - 1}) {
    @content;
  }
}

@mixin mdx {
  @media screen and (min-width: #{$breakpoint-mdx}) and (max-width: #{$breakpoint-lg - 1}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$breakpoint-lg}) and (max-width: #{$breakpoint-xl - 1}) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin gt-mdx {
  @media screen and (min-width: #{$breakpoint-mdx}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin page-header {
  display: grid;
  grid-template-columns: minmax(0, 2fr) 1fr 1fr;
  gap: 20px;
}

@mixin title-format {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

@mixin loader {
  --bg-colour: var(--color-white);
  width: 100%;
  height: 50vh;
  padding: 0 0 70px 20px;
  background-color: var(--bg-colour);
  border-radius: 20px;
}

@mixin tooltip {
  z-index: 9999;
  background-color: var(--color-black-20) !important;
  border-radius: 5px !important;
}

@mixin box-shadow {
  box-shadow: 0 0 30px 0 var(--shadow-color);
}

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@mixin resizeLinear($property, $minSize, $maxSize, $minDeviceWidth, $maxDeviceWidth) {

  #{$property}: calc(#{$minSize}px + (#{strip-unit($maxSize)} - #{strip-unit($minSize)}) * ((100vw - #{strip-unit($minDeviceWidth)}px) / (#{strip-unit($maxDeviceWidth)} - #{strip-unit($minDeviceWidth)})));
}

@mixin resizeLinearMd($property, $minSize, $maxSize) {
  @include md {
    @include resizeLinear($property, $minSize, $maxSize, $breakpoint-md, $breakpoint-lg);
  }
}

@mixin chart-container-grid {
  display: grid;
  grid-template-rows: 1fr 45px;
  grid-template-columns: 45px 1fr;
  align-items: center;
  justify-items: center;
}

@mixin x-axis-grid-position {
  grid-area: 2/2/3/3;
}

@mixin y-axis-grid-position {
  grid-area: 1/1/2/2;
}

@mixin plotly-chart-grid-position {
  grid-area: 1/2/2/3;
}

@mixin swap-chart-grid-position {
  grid-area: 2/1/3/2;
}
