.gates-accordion {
  --icon-bg-colour: #e8e6e6;

  $root: &;
  cursor: pointer;

  &__heading {
    --gate-bg-color: var(--color-white);
    --gate-divider-color: var(--light-color-gray-10);

    position: relative;
    display: flex;
    gap: 3px;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-right: 0;
    background-color: var(--gate-bg-color);
    border-radius: 10px;
    transition: background-color .3s ease-out, border-radius .3s ease-out;

    &::after,
    &::before {
      position: absolute;
      right: 10px;
      left: 10px;
      z-index: 5;
      height: 1px;
      content: '';
    }

    &::after {
      top: 0;
      background-color: var(--gate-divider-color);
      transition: background-color .2s ease-out;
    }

    &::before {
      bottom: -1px;
      background-color: inherit;
      opacity: 0;
      transition: opacity .2s ease-out;
    }

    &:hover,
    &_selected {
      --gate-bg-color: var(--color-white-20);

      #{$root}__dot-mask {
        opacity: 1;
      }
    }

    &:not(&_selected):hover {
      &::after {
        --gate-divider-color: var(--color-white-20);
        right: 0;
        left: 0;
      }
    }

    &_drawn:not(&_hidden) {
      &::after {
        right: 0;
        left: 0;
        opacity: 0;
      }
    }

    &_selected {
      --gate-bg-color: var(--color-yellow-40);

      &::after {
        background-color: inherit;
      }
    }

    &_drawn {
      --gate-bg-color: var(--color-yellow-40);
    }

    &_hidden:not(&_selected) {
      --gate-bg-color: var(--color-white);
      opacity: .6;

      &:hover {
        --gate-bg-color: var(--color-white-20);
        opacity: 1;
      }
    }

    &_ungated {
      --gate-bg-color: var(--color-white);
    }
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    margin-left: -5px;
    border-radius: 5px;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: var(--icon-bg-colour);
    }
  }

  &__eye-icon {
    color: var(--light-color-gray-15);
  }

  &__heading:hover &__visibility-icon {
    opacity: 1;
  }

  &__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: var(--color-gray-10);
    transition: transform .3s;
    transform: rotate(-90deg);

    &_opened {
      transform: rotate(0deg);
    }
  }

  &__visibility-icon {
    opacity: 0;
  }

  .gates-accordion__children-list {
    position: relative;
    padding-left: 20px;
  }

  &__child {
    padding-left: 3px;
  }

  &__container-wrapper {
    width: 100%;
    overflow: hidden;

    &_with-left-gap {
      margin-left: 20px;
    }
  }

  &__gate-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &_full-size {
      position: absolute;
      z-index: 150;
      width: 250px;
      max-width: 250px;
      padding: 8px;
      overflow: initial;
      text-overflow: initial;
      word-break: break-all;
      white-space: initial;
      background-color: var(--color-white);
      border-radius: 10px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    }

    &_highlighted {
      font-weight: bold;
    }
  }

  &__dot-mask {
    padding: 0 10px;
    color: var(--light-gray);
    cursor: pointer;
    opacity: 0;
    transition: opacity .3s ease-out;
  }

  &__popover {
    width: 100%;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  }

  &__button {
    width: 25px;
    height: 25px;
    padding: 2px;
    color: var(--color-black-20);
    background: transparent;
    border-color: transparent;
  }

  &__elements {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    max-height: 100vh;
  }

  &__text {
    --number-column-width: 65px;
    --percent-number-column-width: 60px;

    text-align: right;

    &_extra-space {
      padding-right: 5px;
    }

    &_number-column {
      width: var(--number-column-width);
    }

    &_percent-column {
      width: var(--percent-number-column-width);
      text-align: left;
    }
  }

  .gates-accordion__enter {
    max-height: 0;
    overflow: hidden;
  }

  .gates-accordion__enter_active {
    max-height: 100vh;
    transition: max-height .3s ease-in;
  }

  .gates-accordion__exit {
    max-height: 100vh;
  }

  .gates-accordion__exit_active {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease-out;
  }
}
