@import '@/styles/badge';

.status-badge {
  @include badge;

  display: inline-block;
  padding: 6px 12px;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 20px;

  &__info-button {
    margin-right: -2px;
    margin-left: 6px;
  }
}
