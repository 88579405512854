.dataset {
  --dataset-sequencing-data-column: 250px;
  --dataset-button-column: 40px;
  --dataset-grid-template-columns: repeat(6, 1fr) var(--dataset-sequencing-data-column) var(--dataset-button-column);
  --dataset-panel-content-padding: 0 20px;

  &_edit-mode {
    --dataset-sequencing-data-column: 685px;
  }

  &_minimal-view {
    --dataset-grid-template-columns: repeat(3, 1fr);
    --dataset-panel-content-padding: 0
  }

  &__header {
    display: grid;
    grid-template-columns: var(--dataset-grid-template-columns);
    gap: 20px;
    align-items: center;
  }

  &__wrap-header {
    position: relative;
    padding-block: 10px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background-color: var(--color-white-5);
    }
  }

  &__title {
    margin: 0;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.85;
    color: var(--light-gray);
    text-transform: uppercase;
    letter-spacing: .5px;

    &:first-child {
      padding-left: 10px;
    }
  }

  &__edit-button {
    width: 40px;
    height: 40px;
    padding: 10px;

    &:enabled {
      color: var(--color-black);
      border-color: var(--light-color-gray-10);
      border-width: 1px;
    }

    &_hidden {
      display: none;
    }

    &_selected {
      &:enabled {
        color: var(--color-white);
      }
    }
  }

  &__panel-content {
    gap: 0;
    padding: var(--dataset-panel-content-padding);
  }
}
