@import '@/styles/variables';

.card-wrapper {
  height: max-content;
  overflow: hidden;

  &__full-screen-background {
    position: relative;
    z-index: var(--z-modal-window-full-screen);
    opacity: 0;
    transition: opacity .2s ease-out;

    &::before {
      position: fixed;
      inset: 0;
      content: '';
      background-color: var(--light-color-gray-10);
    }

    &_visible {
      z-index: 110;
      opacity: 1;
    }
  }
}

.card {
  --card-background-color: var(--color-white);
  --selected-card-border-color: var(--color-purple);
  --flip-block-padding: 15px 20px;
  --card-header-gap: 20px;
  --card-header-padding-bottom: 12px;
  --card-header-font-size: 17px;

  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--card-background-color);
  border-radius: 20px;
  transition: border .4s ease-out;
  aspect-ratio: 4 / 3;

  &__main-no-expand {
    width: 100% !important;
  }

  &_minimized {
    --flip-block-padding: 12px;
    --card-header-gap: 12px;
    --card-header-font-size: 15px;
  }

  &__flip-block {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: var(--flip-block-padding);
    background-color: var(--card-background-color);

    &_full-screen { // temporary solution. Delete after refactor the fullscreenon matrix view
      --card-background-color: var(--color-white-20);
      --flip-block-padding: 0 20px 20px;
    }
  }

  &__toggleable-block {
    padding: 20px;
    overflow-x: clip  !important; // temporary solution. Delete after refactor the fullscreenon matrix view
    overflow-y: visible !important; // temporary solution. Delete after refactor the fullscreenon matrix view
    background-color: var(--color-white);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &__gate-list {
    position: relative;
    transform: translate( 15px, -95px); // temporary solution. Delete after refactor the fullscreenon matrix view

    &::before { // temporary solution. Delete after refactor the fullscreenon matrix view
      position: absolute;
      top: 0;
      left: 5px;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--light-color-gray-10);
    }
  }

  &__custom-oftop-content {
    position: absolute;
    inset: 0;
    z-index: 1;
    pointer-events: none;
  }

  &__axis-select {
    &_y {
      @include y-axis-grid-position;
      left: -6px;
    }

    &_x {
      @include x-axis-grid-position;
      bottom: -10px;
    }
  }

  &__no-data {
    position: absolute;
    top: 50%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    transform: translateY(-50%);
  }

  &_selected {
    border: 2px solid var(--selected-card-border-color);
    transition: border .4s ease-out;
  }

  &__header-divider {  // temporary solution. Delete after refactor the fullscreenon matrix view;
    width: 100%;
    margin-block: 20px;
    border: none;
    border-top: 1px solid var(--light-color-gray-10);
  }

  &__header {
    display: flex;
    gap: var(--card-header-gap);
    align-items: center;
    width: 100%;
    padding-bottom: var(--card-header-padding-bottom);
    overflow: hidden;
    border-bottom: solid 1px var(--light-color-gray-10);

    &_full-screen {
      position: relative;
      padding: 12px 20px;
      background-color: var(--color-white);
      border: unset;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      &::after { // temporary solution. Delete after refactor the fullscreenon matrix view
        position: absolute;
        bottom: 0;
        left: 50%;
        width: calc(100% - 40px);
        height: 1px;
        content: '';
        background-color: var(--light-color-gray-10);
        transform: translateX(-50%);
      }
    }

    .header__title,
    & span {
      overflow: hidden;
      font-size: var(--card-header-font-size);
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__small-button {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 6px;
  }

  &__buttons-container {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: auto;

    &_with-vertical-gap {
      padding: 10px 0;
    }
  }

  &__button {
    width: fit-content;
    height: 34px;
  }

  &__chart {
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    flex: 1;
    width: 100%;
    overflow: hidden;

    &_full-screen {
      @include chart-container-grid;
      z-index: 0;
    }
  }

  &__chart-element {
    @include plotly-chart-grid-position;
    width: 100%;
    height: 100%;

  }

  &__skeleton {
    position: absolute;
    inset: 0;
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    text-align: center;
    background-color: var(--color-white-20);
    border-radius: 20px;

    &_time {
      width: 100px;
    }
  }
}

.modebar {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 49px;

  &_full-screen {
    height: max-content;
    padding: 20px;
    background-color: var(--color-white);
  }

  &__control-item {
    --control-item-color: var(--light-color-gray-15);
    --control-item-bg-color: unset;

    display: grid;
    place-content: center;
    width: 40px;
    height: 45px;
    padding: 0;
    color: var(--control-item-color);
    background-color: var(--control-item-bg-color);
    border-radius: 0;
    transition: color .3s ease-out;

    &:hover {
      --control-item-color: var(--color-black);
      --control-item-bg-color: var(--color-yellow-10);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .6;
    }

    &_selected {
      --control-item-color: var(--color-black);
      --control-item-bg-color: var(--color-yellow-10);
    }
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
    height: 45px;
    text-align: left;
    background-color: var(--color-white-20);
    border-radius: 10px;

    & > div {
      width: 100%;
    }
  }

  &__entities-search {
    margin-left: auto;
  }

  &__entities-popover {
    margin-bottom: 25px;
  }
}
