.standard-table {
  &__content {
    padding: 0 20px;
    overflow: hidden;

    &_empty {
      padding-block: 18px;
    }
  }

  &__table {
    border-collapse: collapse;

    th,
    td {
      height: 60px;
      padding: 10px;
      vertical-align: middle;
    }

    th {
      font-size: 13px;
      font-weight: normal;
      color: var(--light-gray);
      text-transform: uppercase;
      letter-spacing: 0.5px;
      white-space: nowrap;
    }

    tr {
      border-top: solid 1px var(--color-white-5);

      &.header-row {
        border-top: none;
      }
    }

    .fit-content {
      width: 1%;
    }
  }
}

.actions {
  &__button {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-color: transparent;
  }
}

.button-actions {
  display: flex;
  gap: 5px;
}

.row-clickable {
  position: relative;
  z-index: 0;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &::after {
    position: absolute;
    inset: -2px -10px;
    z-index: -1;
    content: '';
    background-color: var(--color-white-20);
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  &:hover::after {
    opacity: 1;
  }
}
