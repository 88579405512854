@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  src: url('SFProDisplay-Regular.woff2') format('woff2'), url('SFProDisplay-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: normal;
  src: url('SFProDisplay-RegularItalic.woff2') format('woff2'), url('SFProDisplay-RegularItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  src: url('SFProDisplay-Medium.woff2') format('woff2'), url('SFProDisplay-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 500;
  src: url('SFProDisplay-MediumItalic.woff2') format('woff2'), url('SFProDisplay-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 600;
  src: url('SFProDisplay-SemiboldItalic.woff2') format('woff2'), url('SFProDisplay-SemiboldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  src: url('SFProDisplay-Semibold.woff2') format('woff2'), url('SFProDisplay-Semibold.woff') format('woff');
  font-display: swap;
}
