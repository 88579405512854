.modal-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;

  &__divide {
    border-bottom: 1px solid rgba(136, 136, 136, .3);
  }

  &__close-button {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-left: auto;
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 5px;

    > svg {
      width: 15px;
      height: 15px;
    }
  }
}
