.channel-list {
  --channel-color: var(--color-black);
  --channel-color-disabled: #999;
  --toggle-color: var(--color-gray-70);
  --toggle-color-hover: var(--color-black);
  --toggle-background-color-hover: #f2f2f2;
  --popover-separator-color: var(--color-white-5);
  margin-right: -14px; // Half of the action button - 1px dot size

  &__title {
    margin-bottom: 10px;
    font-size: 17px;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}

.channel {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 15px;

  & + & {
    margin-top: 4px;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    gap: 8px;
    align-items: center;
    height: 100%;
    padding: 0;
  }

  &__color-preview {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background-color: var(--preview-color, var(--color-black));
    border-radius: 50%;
  }

  &__lut {
    display: inline-block;
    width: 18px;
    height: 18px;
  }

  &__lut-image {
    position: relative;
    left: -18px;
    display: block;
    width: 36px;
    max-width: none;
    height: 100%;
    border-radius: 9px;
  }

  &_disabled &__lut-image {
    filter: saturate(.1);
  }

  &_disabled &__color-preview {
    filter: saturate(.1);
  }

  &__label {
    flex-grow: 1;
    line-height: 1.25;
    color: var(--channel-color);
    text-align: left;
    word-break: break-all;
  }

  &_disabled &__label {
    --channel-color: var(--channel-color-disabled);
  }

  &__range {
    --range-gap: 8px;
    position: relative;

    &-slider {
      position: relative;
      width: calc(100% - 36px) !important;
      margin: 0 auto;

      &::before {
        position: absolute;
        left: -16px;
        width: 18px;
        height: 2px;
        content: '';
        background-color: #d8d8d8;
      }

      &::after {
        position: absolute;
        right: -16px;
        width: 18px;
        height: 2px;
        content: '';
        background-color: #d8d8d8;
      }
    }

    &-input {
      & input {
        font-family: SFPro, Arial, sans-serif;
        font-size: 17px;
        color: #404040;
        border-color: #d8d8d8 !important;
      }
    }

    &-input + &-input {
      margin-left: 10px;
    }
  }

  &__action-button {
    display: grid;
    flex-shrink: 0;
    place-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    color: var(--toggle-color);
    border-radius: 10px;
    transition: all .3s ease-out;

    &:hover {
      --toggle-color: var(--toggle-color-hover);
      background-color: var(--toggle-background-color-hover);
    }

    &_visibility {
      margin-right: 4px;

      & > svg {
        width: 24px;
        height: auto;
      }
    }
  }

  &__content:hover ~ &__action-button_options {
    --toggle-color: var(--toggle-color-hover);
  }

  &__popover {
    position: relative;
    right: -20px;
    z-index: 2;
    width: 250px;
    padding: 20px;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  }

  &__label-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__separator {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--popover-separator-color);
  }

  &_disabled &__visibility-button {
    padding-bottom: 2px;
  }
}
