@import '@/styles/experimentDataControlButton';

// TODO: Refactor, use the same approach as for ChannelDetailsTable

.table-wrapper {
  --marker-column-width: 500px;
  --controls-column-width: 80px;
  --table-offset: min(256px, 5vw);

  width: calc(100% - var(--table-offset));
  padding: 20px 10px 5px;
  margin-bottom: 20px;
  margin-left: auto;
  background-color: var(--color-white-20);
  border-radius: 20px;
}

.table-scroll {
  width: 100%;
  padding-bottom: 15px;
  overflow-x: auto;
}

.table {
  position: relative;
  width: 100%;
  border-collapse: collapse;

  &__no-data {
    padding: 15px 0;
  }
}

.table__head {
  position: relative;
  line-height: 1.83;

  &::before {
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    height: 1px;
    content: '';
    background-color: var(--light-color-gray-10);
    opacity: 1;
    transition: opacity .3s ease-out;
  }
}

.table__row {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr var(--marker-column-width) var(--controls-column-width);
  gap: 20px;
  align-items: center;
  padding: 0 10px 15px;
}

.table__head-item {
  padding: 0;
  font-size: 13px;
  font-weight: normal;
  color: var(--light-gray);
  text-transform: uppercase;
  letter-spacing: .5px;

  &_center {
    text-align: center;
  }
}

.row {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr var(--marker-column-width) var(--controls-column-width);
  gap: 20px;
  align-items: center;
  min-height: 56px;
  padding: 5px 10px;
  transition: background-color .3s ease-out;

  &::after,
  &::before {
    position: absolute;
    right: 10px;
    left: 10px;
    height: 1px;
    content: '';
    transition: opacity .3s ease-out;
  }

  &::after {
    bottom: 0;
    background-color: var(--light-color-gray-10);
    opacity: 1;
  }

  &::before {
    top: -1px;
    background-color: var(--color-white-20);
    opacity: 0;
  }

  &:hover {
    position: relative;
    background-color: var(--color-white);
    border-radius: 10px;

    &::after {
      opacity: 0;
    }

    &::before {
      opacity: 1;
    }
  }

  &:last-child {
    &::after {
      background-color: unset;
    }
  }

  .column {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 100%;
    padding: 0;

    &_center {
      justify-content: center;
    }

    &_time {
      text-align: center;

      small {
        display: block;
        line-height: 1;
      }
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
}

.column {
  &__marker-list {
    width: var(--marker-column-width);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__input-wrapper {
    width: 100%;
  }
}

.control-item {
  @include experiment-data-control-button
}

.icons-set {
  margin: 5px 0;

  &_empty {
    width: 35px;
    text-align: center;
  }
}
